// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import Button from './../basic/Button'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../components/basic/Grid'
import messages from './ContactConfirmation.lang'
import baseStyles from './ContactConfirmation.scss'

type Props = {
  ...StyleProps
}

const ContactConfirmation = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <span className={classNames(styles.linkIcon, 'icon-check_circle')} />
        <h1 className={styles.title}>
          {formatMessage(messages.title)}
          <b>!</b>
        </h1>
        <h2 className={styles.subtitle}>{formatMessage(messages.subtitle)}</h2>
        <Button type="submit" styles={styles.btn} color="accent" iconRight={'icon-arrow_03-right-medium'}>
          <a href="javascript:history.back()">{formatMessage(messages.btn)}</a>
        </Button>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ContactConfirmation)
